import {OKTA_API_URL } from '../config'; // Importieren des API-Tokens aus einer separaten Konfigurationsdatei

const fetchGroups = async (countryCode) => {

  const queryParams =  `/api/v1/groups?search=profile.rbGroupType%20eq%20"organization"%20and%20profile.countryCode%20eq%20"${countryCode}"`

  try {
    const response = await fetch(`${OKTA_API_URL}&path=${queryParams}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    let data = await response.json();

    // Sortiert das Ergebnis alphabetisch nach dem Namen der Gruppen
    data.sort((a, b) => a.profile.name.localeCompare(b.profile.name));

    return data; // Gibt die sortierten Daten zurück
  } catch (error) {
    console.error('Failed to fetch groups:', error);
    throw error;
  }
};

export default fetchGroups;
