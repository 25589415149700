import backgroundUrl from './images/background.jpg';

export const loadBackground = () => {
  // Die folgenden Zeilen laden den Hintergrund aus dem Internet.
  // Wenn du sie wieder verwenden möchtest, kommentiere diese Zeilen ein und die neuen Zeilen aus.

  /*
  const backgroundImage = () => {
    switch (process.env.REACT_APP_ENV) {
      case 'local':
      case 'develop':
        return 'https://cdn.api.redbull.com/images/partner_id/RB_Background_With_Env_develop.png';

      case 'quality':
        return 'https://cdn.api.redbull.com/images/partner_id/RB_Background_With_Env_quality.png';

      default:
        return 'https://cdn.api.redbull.com/images/partner_id/RB_Background_With_Env_production.jpg';
    }
  };
  const url = backgroundImage();

  const headTag = document.getElementsByTagName('head')[0];
  const styleTag = document.createElement('style');

  styleTag.innerHTML = `
        :root {
            --background-url: url('${url}') repeat center center fixed !important;
        }
      `;

  headTag.appendChild(styleTag);
  */
  

  const localBackgroundUrl = backgroundUrl; // Verwende das importierte Bild

  const headTag = document.getElementsByTagName('head')[0];
  const styleTag = document.createElement('style');

  styleTag.innerHTML = `
        :root {
            --background-url: url('${localBackgroundUrl}') repeat center center fixed !important;
            --background-size: cover; /* Hintergrundbild wird proportioniert skaliert, um das gesamte Element zu bedecken */
        }
      `;

  headTag.appendChild(styleTag);
};
