import React from 'react';
import './RegistrationFalse.css';
import Logo from '../utils/Logo'; 

const RegistrationFalse = () => {
    return (
        <div className="registration-false-container">
            <div className="registration-false">
                <div className="logo-placeholder">
                    <Logo />
                </div>
                <p className="false-message">
                <strong className="bold-text">Something went wrong!</strong>
                <br></br>
                    <br></br>
                    Failed to assign Organization - please reach out to your responsible.
                </p>
            </div>
        </div>
    );
};

export default RegistrationFalse;
