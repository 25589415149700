import {OKTA_API_URL } from '../config'; // Importieren des API-Tokens aus einer separaten Konfigurationsdatei
/**
 * Erstellt einen neuen Benutzer in Okta mit den gegebenen Details.
 * @param {Object} userDetails - Details des zu erstellenden Benutzers.
 */

const createUser = async (userDetails) => {
  const { firstName, lastName, email } = userDetails;
  const url = `${OKTA_API_URL}&path=/api/v1/users?activate=false`; // URL für die Okta API.

  const headers = {
    'Content-Type': 'application/json',
    'Accept-Language': 'en-US', // Stellt sicher, dass die Antwort auf Englisch ist
  };
  const body = {
    profile: {
      firstName,
      lastName,
      email,
      login: email,
      selfRegister: true,
      regComplete: false,
    }
  };

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers,
      body: JSON.stringify(body),
    });

    const data = await response.json(); // Immer die Antwort in JSON umwandeln, egal ob Erfolg oder Fehler

    if (!response.ok) {
      // Bestimme die Nachricht basierend auf dem Vorhandensein und Inhalt von errorSummary
      let message = "An error has occurred. The user could not be created. Please try again.";
      if (data.errorCauses && data.errorCauses.length > 0 && data.errorCauses[0].errorSummary) {
        message = data.errorCauses[0].errorSummary;
      } else if (data.errorSummary) {
        message = data.errorSummary;
      }

      return {
        responseNumber: response.status, // Verwende den tatsächlichen Statuscode der Antwort
        success: false,
        errorCode: data.errorCode,
        message: message, // Verwende die bestimmte Nachricht
      };
    }

    // Im Erfolgsfall
    return {
      responseNumber: response.status,
      success: true,
      data: data, // Die erfolgreiche Antwort von Okta
    };
  } catch (error) {
    // Dieser Block fängt Netzwerkfehler und andere unerwartete Fehler, die beim Aufrufen der fetch-Funktion auftreten könnten
    console.error('Error creating user:', error);
    // Da es sich um einen Netzwerkfehler handelt, haben wir keinen Statuscode oder Fehlercode von Okta
    return {
      responseNumber: 0, // Kein Statuscode verfügbar
      success: false,
      errorCode: 'NetworkError',
      message: 'An unexpected network error occurred.',
    };
  }
};
export default createUser;