// fetchIso.js
import countries from '../helper/country.json'; // Importiere die JSON-Daten

const fetchIsoCodes = async () => {
  try {
    // Es ist keine Netzwerkanfrage notwendig, da die Daten lokal verfügbar sind

    // Verarbeite die Daten und sortiere sie alphabetisch nach dem Namen des Landes
    const isoOptions = countries.map(country => ({
      value: country.cca2, // Verwende den CCA2-Code als Wert
      label: `${country.cca2} - ${country.name.common}`, // Kombiniere CCA2 und den allgemeinen Namen für das Label
    })).sort((a, b) => a.label.localeCompare(b.label)); // Alphabetische Sortierung

    // Rückgabe der verarbeiteten und sortierten ISO-Optionen
    return isoOptions;
  } catch (error) {
    console.error('Failed to load ISO codes:', error);
    throw error;
  }
};

export default fetchIsoCodes;
