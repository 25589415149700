import React, { useState, useEffect } from 'react';
import './App.css';
import { loadBackground } from './utils/styleutils';
import RegistrationForm from './components/RegistrationForm';
import RegistrationSuccess from './components/RegistrationSuccess';
import RegistrationFalse from './components/RegistrationFalse'; 

function App() {
  const [registrationStatus, setRegistrationStatus] = useState({
    isRegistered: false,
    isSuccess: false
  });

  useEffect(() => {
    loadBackground(); // Lade das Hintergrundbild beim ersten Rendern der Komponente
  }, []);

  const handleRegistrationSuccess = (isSuccess) => {
    setRegistrationStatus({
      isRegistered: true,
      isSuccess: isSuccess
    });
  };

  return (
    <div className="App">
      {
        !registrationStatus.isRegistered ?
        <RegistrationForm onRegistrationSuccess={handleRegistrationSuccess} />
        :
        registrationStatus.isSuccess ? <RegistrationSuccess /> : <RegistrationFalse />
      }
    </div>
  );
}

export default App;
