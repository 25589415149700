import React from 'react';
import './RegistrationSuccess.css';
import Logo from '../utils/Logo'; 

const RegistrationSuccess = () => {
    return (
        <div className="registration-success-container">
            <div className="registration-success">
                <div className="logo-placeholder">
                    <Logo />
                </div>
                <p className="success-message">
                <strong className="bold-text">Almost There!</strong>
                    <br/>
                    Please activate your account by clicking the verification link in the email we sent.
                </p>
            </div>
        </div>
    );
};

export default RegistrationSuccess;
