import React, { useState, useEffect } from 'react';
import './RegistrationForm.css'; 
import Logo from '../utils/Logo'; 
import Select from 'react-select';
import createUser from '../API/createUser'; 
import fetchGroups from '../API/fetchGroup'; 
import fetchIsoCodes from '../API/fetchIso'; 
import { OKTA_URL } from '../config';

import createRequest from '../API/createRequest';

const RegistrationForm = ({ onRegistrationSuccess }) => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    country: '',
    distributorPartner: '',
  });

  const [fieldErrors, setFieldErrors] = useState({});
  const [groups, setGroups] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);
  const [error, setError] = useState('');

  useEffect(() => {
    fetchIsoCodes().then(isoCodes => {
      setCountryOptions(isoCodes);
    }).catch(error => {
      console.error('Error fetching ISO codes:', error);
      setError('Failed to fetch country codes');
    });
  }, []);


  useEffect(() => {
    if (formData.country) {
      fetchGroups(formData.country)
        .then(data => {
          if (data.length === 0) {
            setError('No company is currently registered in this country. Please reach out to the appropriate contact within your organization for assistance.');
            setGroups([]);
          } else {
            setError('');
            setGroups(data.map(group => ({ value: group.id, label: group.profile.name })));
          }
        })
        .catch(error => {
          console.error('Error fetching groups:', error);
          setError('Failed to fetch distributor partners');
        });
    } else {
      setGroups([]);
    }
  }, [formData.country]);

  // Verbesserte E-Mail-Validierungsfunktion
const validateEmail = (email) => {
  const emailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\\.,;:\s@\"]+\.)+[^<>()[\]\\.,;:\s@\"]{2,})$/i;
  return emailRegex.test(email);
};


  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    // Clear field-specific error
    setFieldErrors({ ...fieldErrors, [e.target.name]: '' });
  };

  const handleSelectChange = (selectedOption, action) => {
    if (action.name === 'country') {
      setFormData({ ...formData, distributorPartner: '', [action.name]: selectedOption ? selectedOption.value : '' });
      setFieldErrors({ ...fieldErrors, distributorPartner: '' }); // Feldspezifischen Fehler für distributorPartner löschen
    } else {
      setFormData({ ...formData, [action.name]: selectedOption ? selectedOption.value : '' });
    }
    // Feldspezifischen Fehler löschen
    setFieldErrors({ ...fieldErrors, [action.name]: '' });
  };


  
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    let newErrors = {};
    let isFormFullyFilled = true;
  
    // Überprüfe, ob alle Felder ausgefüllt sind
    Object.keys(formData).forEach(key => {
      if (!formData[key].trim()) {
        isFormFullyFilled = false;
        newErrors[key] = 'This field is required'; // Markiere fehlende Felder
      }
    });
  
    if (!isFormFullyFilled) {
      // Wenn nicht alle Felder ausgefüllt sind, zeige diese Fehlermeldung an
      setFieldErrors(newErrors);
      setError('All fields are required. Please complete the missing information.');
      return;
    } 
  
    // Überprüfe nun die E-Mail-Validität
    if (!validateEmail(formData.email)) {
      // Speziell für das E-Mail-Feld, wenn ungültig
      newErrors.email = 'Please enter a valid email address.';
      setFieldErrors(newErrors);
      setError('Please enter a valid email address.');
      return;
    }
  
    // Wenn keine Fehler vorliegen, bereinige die Fehlermeldungen und setze den Versuch fort, den Benutzer zu erstellen
    setError('');
    setFieldErrors({}); // Bereinige alle Feldfehler, da die Validierung erfolgreich war

    try {
      const userResponse = await createUser({
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.email,
        groupId: formData.distributorPartner,
      });
  
      if (userResponse.success) {
        const requestResponse = await createRequest(userResponse.data.id, formData.distributorPartner);
        if (requestResponse && requestResponse.status >= 200 && requestResponse.status < 300) {
          onRegistrationSuccess(true);
        } else {
          onRegistrationSuccess(false);
          setError('Failed to create additional request.');
          return; // Beendet die Funktion frühzeitig, um weitere Ausführung zu verhindern
        }
      } else {
        // Hier überprüfen wir die spezifische Fehlermeldung und reagieren entsprechend
        if (userResponse.message === "login: An object with this field already exists in the current organization") {
          setError(<span>This address is already linked to an existing account. To continue, <a href={OKTA_URL}>log in</a>.</span>);
        } else {
          // Für alle anderen Fehler verwenden wir die Fehlermeldung wie sie ist
          setError(userResponse.message || 'Registration failed. Please try again.');
        }
      }
    } catch (error) {
      console.error('Error during registration:', error);
      setError(error.message || 'Registration failed. Please try again.');
      onRegistrationSuccess(false);
    }
  };
  
  
  
  



  const getCustomStyles = (fieldName) => ({
    control: (provided, state) => ({
      ...provided,
      borderColor: fieldErrors[fieldName] ? 'red' : provided.borderColor,
      '&:hover': { borderColor: fieldErrors[fieldName] ? 'darkred' : provided['&:hover'].borderColor },
      color: '#000F1E',
      fontSize: '14px',
      fontWeight: 'normal',
    }),
    option: (provided, state) => ({
      ...provided,
      color: '#000F1E',
    }),
    placeholder: (provided, state) => ({
      ...provided,
      color: 'rgba(0, 15, 30, 0.4)',
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: '#000F1E',
    }),
  });




  return (
    <div className="registration-form-container">
      <form className="registration-form" onSubmit={handleSubmit} noValidate>
        <div className="registration-form-logo-placeholder"><Logo /></div>
        
        
        <div className="account-text-container">
  <p className="signup-text">Sign-Up</p>
  <p className="account-hint">
    Already have an account? <a href={OKTA_URL}>login</a>
  </p>
</div>

        <input
          type="text"
          name="firstName"
          placeholder="First Name"
          className={fieldErrors.firstName ? 'input-error' : ''}
          value={formData.firstName}
          onChange={handleInputChange}
        />
        <input
          type="text"
          name="lastName"
          placeholder="Last Name"
          className={fieldErrors.lastName ? 'input-error' : ''}
          value={formData.lastName}
          onChange={handleInputChange}
        />
        <input
          type="email"
          name="email"
          placeholder="Email"
          className={fieldErrors.email ? 'input-error' : ''}
          value={formData.email}
          onChange={handleInputChange}
          
        />
        <Select
        name="country"
        options={countryOptions}
        value={countryOptions.find(option => option.value === formData.country)}
        onChange={handleSelectChange}
        placeholder="Country"
        classNamePrefix="select"
        styles={getCustomStyles('country')} // Anwendung der customStyles basierend auf dem Feld "country"
      />
        {formData.country && (
        <Select
          name="distributorPartner"
          options={groups}
          value={groups.find(option => option.value === formData.distributorPartner) || null}
          onChange={handleSelectChange}
          placeholder="Distributor Partners"
          classNamePrefix="select"
          styles={getCustomStyles('distributorPartner')} // Anwendung der customStyles basierend auf dem Feld "distributorPartner"
        />
      )}
        {error && <p className="form-error">{error}</p>}
        <button type="submit" className="button">Complete Registration</button>
        {/* Hier bitte die Links einfügen */}
        <div className="form-footer">
        <p>By completing registration, you accept <a href="/terms-of-use">Terms of Use</a> and <a href="/privacy-policy">Privacy Policy</a>. </p>
        
      </div>
      </form>
    </div>
  );
};

export default RegistrationForm;
