import { OKTA_WORKFLOW_REQUEST_URL } from '../config'; // Importieren des API-Tokens aus einer separaten Konfigurationsdatei

const createRequest = async (oktaID, groupID) => {
    const url = OKTA_WORKFLOW_REQUEST_URL;
    const body = { oktaID, groupID };

    // Überprüfe, ob oktaID oder groupID leer oder null sind
    if (!oktaID || !groupID) {
        console.error('Error invoking workflow: Object not complete');
        throw {
            status: 500,
            message: 'Object not complete. Both oktaID and groupID are required.',
            errorCode: 'InvalidInput'
        };
    }

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body),
      });

      // Rückgabe der JSON-Antwort und des Status, wenn 202
      if (response.status >= 200 && response.status < 300) {
        return {
          status: response.status,
          data: await response.json()
        };
      }

      if (!response.ok) {
        // Wirf einen angepassten Fehler, der den Statuscode einschließt
        throw {
          status: response.status, // Behalte den Statuscode bei
          message: `HTTP error! status: ${response.status}`,
          isAxiosError: true
        };
      }

      return await response.json();
    } catch (error) {
      console.error('Error invoking workflow:', error);
      if (!error.isAxiosError) {
        // Handle non-Axios errors that might not have a status field
        error.status = 500;
      }
      throw error; // Weitergeben des angepassten Fehlers
    }
};

export default createRequest;
