import React from 'react';
import logoImage from '../utils/images/logo.png'; // Importiere das Logo-Bild aus dem lokalen Ordner
import './Logo.css';


const Logo = ({ src }) => {
  return (
    <div className="logo-container">
      {/* Laden aus dem Internet wurde ersetzt durch ein Bild im  Project Ordner  */}
      {/* <img src="https://cdn.api.redbull.com/images/partner_id/redbull-partnerid-logo-color-centered.png" alt="Logo" /> */}
      
      <img src={logoImage} alt="Logo" /> {/* Verwende den importierten Pfad als src-Attribut */}
    </div>
  );
};

export default Logo;
